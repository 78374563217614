@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #006633; // primary color for all components
@link-color: #006633; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@table-header-bg: #fff;
@table-header-color: @heading-color;
@table-header-sort-bg: #FFF0E;
@table-body-sort-bg: #fafafa;
@table-row-hover-bg: #FFF0E;
@table-selected-row-color: inherit;
@table-selected-row-bg: @primary-1;
@table-body-selected-sort-bg: @table-selected-row-bg;
@table-selected-row-hover-bg: darken(#FFF0E, 2%);
@table-expanded-row-bg: #fbfbfb;
@table-padding-vertical: 16px;
@table-padding-horizontal: 16px;
@table-padding-vertical-md: (@table-padding-vertical * 3 / 4);
@table-padding-horizontal-md: (@table-padding-horizontal / 2);
@table-padding-vertical-sm: (@table-padding-vertical / 2);
@table-padding-horizontal-sm: (@table-padding-horizontal / 2);
@table-border-color: @border-color-split;
@table-border-radius-base: @border-radius-base;
@table-footer-bg: #FFF0E;
@table-footer-color: @heading-color;
@table-header-bg-sm: @table-header-bg;
@table-font-size: @font-size-base;
@table-font-size-md: @table-font-size;
@table-font-size-sm: @table-font-size;
@table-header-cell-split-color: rgba(0, 0, 0, 0.06);

@primary-color: #006633;